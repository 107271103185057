import * as e from "../../data/enums";
import * as image from "../../data/constants/images";
import React, { useState, useCallback, useEffect } from "react";
import { handleError } from "../../utils/sharedUtils";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import { useTeacher } from "../../context/UserContext";
import { isSafariBrowser } from "../../utils/deviceCheck";
import { FirebaseManager } from "../../services/firebase/FirebaseManager";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { useAlert } from "../../context/AlertContext";
import { Helmet } from "react-helmet";

const loginLink = "https://student.auriseartraining.com/login";

export default function Login() {
	const { t } = useTranslation("LoginPages");
	const [showChoices, setShowChoices] = useState<boolean>(true);

	const iAmATeacher = localStorage.getItem("iAmATeacher");

	function handleClickTeacher() {
		localStorage.setItem("iAmATeacher", "true");
		setShowChoices(false);
	}

	function handleClickStudent() {
		localStorage.removeItem("iAmATeacher");
		window.location.href = loginLink;
	}

	useEffect(() => {
		if (iAmATeacher) setShowChoices(false);
	}, [iAmATeacher]);

	return (
		<main className={`loginSignup ${isSafariBrowser() ? "is-safari" : ""}`}>
			<Helmet>
				<title>{t("loginPageTitle")}</title>
				<meta name="robots" content="noindex, nofollow" />
			</Helmet>

			{showChoices ? (
				<>
					<h2>{t("whereDoYouWantToLogin")}</h2>
					<div className="flexRow">
						<button className="buttonOpen" style={{ padding: "1em" }} onClick={handleClickStudent}>
							{t("studentPortal")}
						</button>
						<button onClick={handleClickTeacher}>{t("teacherPortal")}</button>
					</div>
				</>
			) : (
				<LoginAsTeacher />
			)}
		</main>
	);
}

function LoginAsTeacher() {
	const { setTeacher } = useTeacher();
	const [emailLogin, setEmailLogin] = useState<string>("");
	const [passwordLogin, setPasswordLogin] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [showPassword, setShowPassword] = useState(false);
	const { showAlert, triggerAlert } = useAlert();
	const [alertCount, setAlertCount] = useState(0);
	const { t } = useTranslation("LoginPages");

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	useEffect(() => {
		console.log("showAlert", showAlert, alertCount);
		if (showAlert) setAlertCount(alertCount + 1);
		if (!showAlert && alertCount > 0) if (!showAlert) window.location.href = loginLink;
	}, [showAlert]);

	const logInTeacher = useCallback(async () => {
		setLoading(true);
		try {
			await FirebaseManager.login(emailLogin.toLocaleLowerCase().trim(), passwordLogin, setTeacher);
		} catch (error: any) {
			console.log("error", error);
			if (error.toString().includes("User is not a teacher")) {
				triggerAlert(
					"This email belongs to a student. You are trying to log in as a teacher. We will redirect you to the student login page."
				);
			}
			setErrorMessage(handleError(error).userMessage);
			Sentry.captureException(error);
		} finally {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailLogin, passwordLogin]);

	return (
		<>
			{loading && <Loading />}
			{errorMessage && <div className="error">{errorMessage}</div>}
			<form
				id="login"
				onSubmit={(e) => {
					e.preventDefault();
					logInTeacher();
				}}
			>
				<label>
					{t("email")}
					<input
						id="email"
						className="text-area"
						type="text"
						value={emailLogin}
						onChange={(e) => setEmailLogin(e.target.value)}
						required
						autoComplete="email"
					/>
				</label>
				<label className="passwordField">
					{t("password")}
					<input
						id="password"
						className="text-area"
						type={showPassword ? "text" : "password"}
						value={passwordLogin}
						onChange={(e) => setPasswordLogin(e.target.value)}
						required
						autoComplete="current-password"
					/>
					<img
						onClick={togglePasswordVisibility}
						className="eyeIcon"
						src={showPassword ? image.ICONS[e.Icons.EYE_OPEN] : image.ICONS[e.Icons.EYE_CLOSED]}
						alt="Toggle password visibility"
					/>
				</label>
				{!loading && <button type="submit">{t("logIn")}</button>}
			</form>
			<div className="flexRow">
				<Link target="blank" className="legalDisclaimer" to="https://auriseartraining.com/en-us/legal">
					{t("loginDisclaimer")}
				</Link>
				{/* <Link to="/signup">Or Sign Up</Link> */}
				<Link to="/forgotPassword">{t("forgotPassword")}</Link>
			</div>
		</>
	);
}
