import React, { useCallback, useEffect, useState } from "react";
import * as e from "../../data/enums";
import * as img from "../../data/constants/images";
import { isSafariBrowser } from "../../utils/deviceCheck";
import { FirebaseManager } from "../../services/firebase/FirebaseManager";
import { useAlert } from "../../context/AlertContext";
import Loading from "../../components/Loading";
import { handleError } from "../../utils/sharedUtils";
import fetchSchoolsAPI from "../../services/api/fetchSchools";
import { useTeacher } from "../../context/UserContext";

export default function SignUpTeacher(): JSX.Element {
	const { triggerAlert } = useAlert();
	const { teacher } = useTeacher();
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [schoolName, setSchoolName] = useState<string>("");
	const [schoolID, setSchoolID] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [schools, setSchools] = useState<any[]>([]); // Change to state

	useEffect(() => {
		const fetchSchools = async () => {
			if (schools.length > 0) return;

			// Check if the user is authenticated
			const authInstance = await FirebaseManager.getAuthInstance();
			if (!authInstance.currentUser) return; // Exit if not authenticated

			try {
				if (!teacher) return;
				const fetchedSchools = await fetchSchoolsAPI(teacher.Email);
				console.log(fetchedSchools);
				setSchools(fetchedSchools);
			} catch (error) {
				triggerAlert("Error fetching schools", "error");
			}
		};

		setTimeout(fetchSchools, 2000);
	}, []);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleSignUp = useCallback(async () => {
		setLoading(true);
		console.log(firstName, lastName, email, schoolName, schoolID, password);
		try {
			await FirebaseManager.signUpTeacher(
				firstName,
				lastName,
				email,
				schoolName,
				schoolID,
				password,
				setErrorMessage
			);
			triggerAlert("User sign-up successful!", "success");
		} catch (error) {
			triggerAlert("User sign-up failed!", "error");
			setErrorMessage(handleError(error).userMessage);
		} finally {
			setFirstName("");
			setLastName("");
			setEmail("");
			setPassword("");
			setErrorMessage("");
			setLoading(false);
		}
	}, [firstName, lastName, email, password, setErrorMessage, triggerAlert]);

	return (
		<div className={`basicCard loginSignup ${isSafariBrowser() ? "is-safari" : ""}`}>
			<h1>Sign Up Teacher</h1>
			<br /> <br />
			{loading && <Loading />}
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handleSignUp();
				}}
			>
				<label>
					First Name
					<input
						className="text-area"
						type="text"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
						required
					/>
				</label>
				<label>
					Last Name
					<input
						className="text-area"
						type="text"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
						required
					/>
				</label>
				<label>
					Email
					<input
						className="text-area"
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value.toLowerCase())}
						required
					/>
				</label>

				<label className="schoolField">
					School
					<select
						value={schoolName}
						onChange={(e) => {
							const selectedSchool = schools.find((school) => school.SchoolName === e.target.value);
							if (selectedSchool) {
								setSchoolName(selectedSchool.SchoolName);
								setSchoolID(selectedSchool.id);
							}
						}}
						required
					>
						<option value="" disabled>
							Select a school
						</option>
						{schools.map((school, index) => (
							<option key={index} value={school.SchoolName}>
								{school.SchoolName}
							</option>
						))}
					</select>
				</label>

				<label className="passwordField">
					Password
					<input
						id="password"
						className="text-area"
						type={showPassword ? "text" : "password"}
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
						autoComplete="current-password"
					/>
					<img
						onClick={togglePasswordVisibility}
						className="eyeIcon"
						src={showPassword ? img.ICONS[e.Icons.EYE_OPEN] : img.ICONS[e.Icons.EYE_CLOSED]}
						alt="Toggle password visibility"
					/>
				</label>
				{errorMessage && <div className="error">{errorMessage}</div>}
				<button className="search-button" type="submit" value="Sign Up">
					Sign Up
				</button>
			</form>
		</div>
	);
}
