import * as t from "../../data/types";
import * as e from "../../data/enums";
import React, { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { convertDateToStringDayMonth, parseDate } from "../../utils/dates";
import { customLegend, generateDateSeries, useDateRange } from "./lineChartUtils";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function LineChartStacked({ user, timeframe }: { user: t.User; timeframe: e.TimeFrame }) {
	const userStartDate = user.DailyStats[0] ? parseDate(user.DailyStats[0].Date) : new Date();
	const { startDate, endDate } = useDateRange(timeframe, userStartDate);
	const { labels, datasets } = useChartDataModuleProgress(user, startDate, endDate);
	const [visibility, setVisibility] = useState(new Array(datasets.length).fill(true));

	const options = {
		scales: {
			y: {
				stacked: true,
			},
		},
		plugins: {
			legend: {
				display: false,
			},
		},
	};

	const toggleDataset = (index: number) => {
		const newVisibility = visibility.slice();
		newVisibility[index] = !newVisibility[index];
		setVisibility(newVisibility);
	};

	const reversedDatasets = datasets
		.filter((_, index) => visibility[index]) // First, filter out datasets based on visibility
		.reverse(); // Then, reverse the order of the visible datasets

	return (
		<>
			<Line data={{ labels, datasets: reversedDatasets.filter((_, i) => visibility[i]) }} options={options} />
			{customLegend(datasets, visibility, toggleDataset, `33%`)}
		</>
	);
}

const colorSettings = {
	progress: "130, 120, 200",
	intervalComparison: "185, 205, 170",
	intervalRecognition: "218, 224, 184",
	harmonicContext: "251, 242, 196",
	scaleRecognition: "240, 218, 165",
	melodicDictation: "229, 193, 133",
	triadInversion: "222, 166, 111",
	chordRecognition: "214, 138, 88",
	progressionsMajor: "207, 110, 65",
	progressionsMinor: "203, 96, 54",
	progressionsJazz: "203, 86, 44",
};

const useChartDataModuleProgress = (user: t.User, startDate: Date, endDate: Date) =>
	useMemo(() => {
		const labels = generateDateSeries(startDate, endDate);
		const intervalComparison = processData(user.DailyStats, "ProgressIntervalComparison", labels);
		const intervalRecognition = processData(user.DailyStats, "ProgressIntervalRecognition", labels);
		const harmonicContext = processData(user.DailyStats, "ProgressHarmonicContext", labels);
		const scaleRecognition = processData(user.DailyStats, "ProgressScaleRecognition", labels);
		const melodicDictation = processData(user.DailyStats, "ProgressMelodicDictation", labels);
		const triadInversion = processData(user.DailyStats, "ProgressTriadInversion", labels);
		const chordRecognition = processData(user.DailyStats, "ProgressChordRecognition", labels);
		const progressionsMajor = processData(user.DailyStats, "ProgressProgressionsMajor", labels);
		const progressionsMinor = processData(user.DailyStats, "ProgressProgressionsMinor", labels);
		const progressionsJazz = processData(user.DailyStats, "ProgressProgressionsJazz", labels);

		return {
			labels: labels,
			datasets: [
				createDataset("Interval Comparison", intervalComparison, labels, colorSettings.intervalComparison),
				createDataset("Interval Recognition", intervalRecognition, labels, colorSettings.intervalRecognition),
				createDataset("Harmonic Context", harmonicContext, labels, colorSettings.harmonicContext),
				createDataset("Scale Recognition", scaleRecognition, labels, colorSettings.scaleRecognition),
				createDataset("Melodic Dictation", melodicDictation, labels, colorSettings.melodicDictation),
				createDataset("Triad Inversion", triadInversion, labels, colorSettings.triadInversion),
				createDataset("Chord Recognition", chordRecognition, labels, colorSettings.chordRecognition),
				createDataset("Progressions Minor", progressionsMinor, labels, colorSettings.progressionsMinor),
				createDataset("Progressions Major", progressionsMajor, labels, colorSettings.progressionsMajor),
				createDataset("Progressions Jazz", progressionsJazz, labels, colorSettings.progressionsJazz),
			],
		};
	}, [user, startDate, endDate]);

function processData(stats: any[], key: keyof any, dateLabels: string[]): Record<string, string> {
	// Sort stats by date to ensure cumulative totals are calculated correctly
	const sortedStats = stats.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());

	let runningTotal = 0;
	const cumulativeTotals: Record<string, string> = {};

	// Initialize cumulative totals for all dateLabels to zero or carry forward the last known value
	dateLabels.forEach((label) => (cumulativeTotals[label] = "0"));

	// Update cumulative totals based on actual data
	sortedStats.forEach((stat) => {
		const date = convertDateToStringDayMonth(parseDate(stat.Date));
		if (stat[key] !== undefined) runningTotal += Math.round(stat[key] / 10); // Adjust rounding as necessary
		if (date in cumulativeTotals) cumulativeTotals[date] = runningTotal.toString();
	});

	// Ensure that each date carries forward the last known cumulative value if there's no new data
	let lastValue = "0";
	dateLabels.forEach((date) => {
		if (cumulativeTotals[date] === "0") cumulativeTotals[date] = lastValue;
		else lastValue = cumulativeTotals[date];
	});

	return cumulativeTotals;
}

function createDataset(label: string, dataMap: Record<string, string>, labels: string[], color: string) {
	return {
		label,
		data: labels.map((label) => dataMap[label] || "0"),
		borderColor: `rgb(${color})`,
		backgroundColor: `rgba(${color}, 0.5)`,
		pointBackgroundColor: `rgb(${color})`,
		pointBorderColor: `rgb(${color})`,
		cubicInterpolationMode: "monotone" as "monotone" | "default",
		tension: 0.4,
		stack: "Stack 0",
		fill: true,
	};
}
