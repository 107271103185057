import React from "react";
import * as t from "../data/types";
import * as e from "../data/enums";
import * as image from "../data/constants/images";
import * as music from "../data/constants/musical";
import { UserFriendlyError, IAuthError, ERROR_MESSAGES } from "../data/constants/errorMessages";

export function replaceBwithFlat(content: string, color: "white" | "dark"): JSX.Element {
	if (typeof content !== "string") {
		console.error("Expected string but received:", content);
		return <></>;
	}
	if (content.startsWith("b"))
		return (
			<>
				<FlatIcon color={color} />
				{content[1] === "b" && <FlatIcon color={color} />}
				{content[1] === "b" ? content.slice(2) : content.slice(1)}
			</>
		);
	if (content.startsWith("#"))
		return (
			<>
				<SharpIcon color={color} />
				{content.slice(1)}
			</>
		);
	return <>{content}</>;
}

export function FlatIcon({ color }: { color: "white" | "dark" }): JSX.Element {
	const source = color === "white" ? image.ICONS[e.Icons.FLAT_ICON] : image.ICONS[e.Icons.FLAT_ICON_DARK];
	return <img className={"flatIcon " + color} src={source} alt="flat" onContextMenu={preventDefaultContext} />;
}

export function SharpIcon({ color }: { color: "white" | "dark" }): JSX.Element {
	const source = color === "white" ? image.ICONS[e.Icons.SHARP_ICON] : image.ICONS[e.Icons.SHARP_ICON_DARK];
	return <img className={"flatIcon " + color} src={source} alt="sharp" onContextMenu={preventDefaultContext} />;
}

// TODO: check if the error is related to not having an internetconnection
export function handleError(error: any): UserFriendlyError {
	// Log technical message for developers
	console.log("Error log:", error);

	if (typeof error.code === "string" && typeof error.message === "string") {
		// Handle known error codes
		if (error.code in ERROR_MESSAGES) return ERROR_MESSAGES[error.code as IAuthError["code"]];

		// Handle unknown error codes
		return {
			userMessage:
				"An unexpected error occurred. You might not be connected to the internet. Please try again later.",
			logMessage: `Unknown error code: ${error.code}. Message: ${error.message}`,
		};
	}

	// Handle completely unexpected errors
	return {
		userMessage:
			"An unexpected error occurred. You might not be connected to the internet. Please try again later.",
		logMessage: `Unexpected error structure: ${JSON.stringify(error, null, 2)}`,
	};
}

export function capitalizeFirstLetter(str: string): string {
	return str
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(" ");
}

export function spaceBeforeCapitals(input: any): string {
	if (typeof input !== "string") return "";
	if (!input || input.length === 0) return "";
	return input.replace(/(?=[A-Z])/g, " ").trim();
}

export function spaceBeforeCaptialsNumbersAndEnpercent(input: any): string {
	if (typeof input !== "string") return "";
	if (!input || input.length === 0) return "";
	return input.replace(/(?=[A-Z]|&|[1-9])/g, " ").trim();
}

export function determineSuperscriptText(input: number): string {
	const lastTwoDigits = input % 100;
	if (lastTwoDigits > 3 && lastTwoDigits < 21) return "th";
	const lastDigit = input % 10;
	if (lastDigit === 1) return "st";
	if (lastDigit === 2) return "nd";
	if (lastDigit === 3) return "rd";
	return "th";
}

export function getUserTimezone() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function preventDefaultContext(event: React.MouseEvent) {
	event.preventDefault();
}

export function createUniqueID(): string {
	const timestamp = Date.now().toString(36); // Convert timestamp to base 36 for compactness
	const randomString = Math.random().toString(36).substring(2, 18); // Generate a random string

	return (timestamp + randomString).substring(0, 20); // Combine and ensure length is 20
}

export function getUserProgressModules(user: t.User | null, module: e.Modules) {
	return (user && user.Progress && user.Progress.modules && user.Progress.modules[module]) || null;
}

export function getUserProgressDifficulties(user: t.User | null, module: e.Modules) {
	return (
		(user &&
			user.Progress &&
			user.Progress.modules &&
			user.Progress.modules[module] &&
			user.Progress.modules[module].difficulties) ||
		null
	);
}

export function getUserProgressLevel(user: t.User | null, module: e.Modules, difficulty: string, id: string) {
	return (
		(user &&
			user.Progress &&
			user.Progress.modules &&
			user.Progress.modules[module] &&
			user.Progress.modules[module].difficulties &&
			user.Progress.modules[module].difficulties[difficulty] &&
			user.Progress.modules[module].difficulties[difficulty].exercises &&
			user.Progress.modules[module].difficulties[difficulty].exercises[id]) ||
		null
	);
}

export function isProgressionModule(module: string): boolean {
	return (
		module === e.Modules.PROGRESSIONS_MAJOR ||
		module === e.Modules.PROGRESSIONS_MINOR ||
		module === e.Modules.PROGRESSIONS_JAZZ
	);
}

export function isDictationModule(module: e.Modules) {
	return module === e.Modules.MELODIC_DICTATION || isProgressionModule(module);
}

export function createExerciseID(module: string, difficulty: string, options: string[]) {
	return module + "." + difficulty + "." + options.sort().join("_");
}

export function sortIntervals(intervals: string[]) {
	return intervals.sort((a, b) => {
		if (music.INTERVAL_MAP[a] < music.INTERVAL_MAP[b]) return -1;
		if (music.INTERVAL_MAP[a] > music.INTERVAL_MAP[b]) return 1;
		return 0;
	});
}

export function sortDegrees(degrees: string[]) {
	return degrees.sort((a, b) => {
		if (music.CHORD_ROOT_MAP[a] < music.CHORD_ROOT_MAP[b]) return -1;
		if (music.CHORD_ROOT_MAP[a] > music.CHORD_ROOT_MAP[b]) return 1;
		if (a.includes(e.InvPrefix.ROOT)) return -1;
		return 0;
	});
}

export function isRandomVoicing(movement: e.Movement): boolean {
	return (
		movement === e.Movement.ASCENDING_RANDOM_VOICING ||
		movement === e.Movement.DESCENDING_RANDOM_VOICING ||
		movement === e.Movement.HARMONY_RANDOM_VOICING
	);
}

export function clamp(value: number, min: number, max: number) {
	return Math.min(Math.max(value, min), max);
}

export function formatScore(score: number | string | null) {
	if (score === null) return "-";
	const formattedScore = score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	return formattedScore;
}
